import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CommonService } from "../../../shared/common.service";
import { AuthServiceLocal } from "../../auth.service";
import { Router } from "@angular/router";
import { HttpResponse } from "@angular/common/http";
import { StreamService } from "../../../shared/stream.service";
import { MatSnackBar } from "@angular/material";
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm: FormGroup;
  resetForm:FormGroup;
  formSubmitted:boolean = false;
  isLoading:boolean = false;
  show: boolean = false;
  pwdShow: boolean = false;
  isLogin:boolean = true;
  isSubmitted:boolean = false;
  emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  message: string = "";
  type:string = "0";
  modelOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false
  };
  private modalRef: NgbModalRef;
  closeResult: string;
  OtpForm: FormGroup;
  otpToken: any;
  otpValue:any ="";
  constructor(  
   
	private modalService: NgbModal,
	private fb: FormBuilder,
    private authServices: AuthServiceLocal,
    private commonService: CommonService,
    private streamService: StreamService,
    private router: Router,
    private _snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.initialiseForms();
  }

 

  initialiseForms() {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      password: ["", [Validators.required, Validators.minLength(8)]],
      type: ["0", [Validators.required]]
    });
    this.resetForm = this.fb.group({
      email: ["", [Validators.required, Validators.pattern(this.emailPattern)]],
      otp: ["", [Validators.required]],
      newPassword: ["", [Validators.required, Validators.minLength(8)]],
    });
    
  }
    openModal(content, btn) {

    this.modelOptions = {
      backdrop: "static",
      keyboard: false,
      size: "md",
      centered: true
    };
    this.initialiseForms();
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  
  

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }



//  =================================================================================================
//           TERMINAL 2  CODE


  login() {
    this.isSubmitted = true;
    if((this.loginForm.controls["email"].value !="")||(this.loginForm.controls["password"].value!="")){
     this.loginForm.invalid;
    }
    if (this.loginForm.valid) {
      this.isLoading = true;
      let req = {
        email: this.loginForm.controls["email"].value.trim(),
        password: this.loginForm.controls["password"].value.trim(),
        otpToken:this.otpToken
      };
      if(this.loginForm.valid){
        this.isLoading = true;
      this.authServices.validateLogin(req).subscribe(
        (res: HttpResponse<any>) => {
          this.isLoading = false;
          if ((res["message"] = "Success")) {
            this.commonService.setStorage("token", res.headers.get("Authorization"));
            console.log("LOFIN")
            this.router.navigate(["/main/dashboard-management"]);
            
          } else {
          }
        },
        err => {
          this.isLoading = false;
        }
      );
      
  }
}
  }
  navigateOTP(value) {
    if (value == 'true') {
      this.isLogin = true;
      this.loginForm.reset(); 
      this.initialiseForms();

    } else {
      this.isLogin = false;
  
    
    }
  }
  onOtpChange(event:any) {
    this.otpValue = event;
  }

  verifyOtp() {
    if(this.otpValue.length > 3) {
      let req = {
        email: this.loginForm.controls["email"].value.trim(),
        otp:parseInt(this.otpValue),
        type:'AL'
      };
      this.authServices.verifyOtp(req).subscribe(
        (res: HttpResponse<any>) => {
          this.isLoading = false;
          if ((res.body["message"] == "Success")) {
            this.otpToken = res.body.data.token;
            this.login()
            
          } else {
          }
        },
        err => {
          this.isLoading = false;
        }
      );
      
    }
  }
  createOtp() {
    this.isSubmitted = true;
    if((this.loginForm.controls["email"].value !="")||(this.loginForm.controls["password"].value!="")){
     this.loginForm.invalid;
    }
    if (this.loginForm.valid) {
      this.isLoading = true;
      let req = {
        email: this.loginForm.controls["email"].value.trim(),
        password: this.loginForm.controls["password"].value.trim(),
        type:'AL'
      };
      if(this.loginForm.valid){
        this.isLoading = true;
      this.authServices.createOtp(req).subscribe(
        (res: HttpResponse<any>) => {
          this.isLoading = false;
          if ((res["message"] = "Success")) {
            this.navigateOTP('false')
            
          } else {
          }
        },
        err => {
        
          this.isLoading = false;
        }
      );
      
  }
}
  }
 
 forgotPassword() {
    this.pwdShow = !this.pwdShow;
  }

  sendRouteVal(index) {
      this.streamService.streamMessage(index)
      // this.messageEvent.emit(index);
    }
    password() {
      this.show = !this.show;
    }
   
  }
function content(content: any, btn: any) {
  throw new Error('Function not implemented.');
}

function btn(content: (content: any, btn: any) => void, btn: any) {
  throw new Error('Function not implemented.');
}

