<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container h-100">
  <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->
    <mat-sidenav
      #snav
      id="snav"
      class="dark-sidebar pl-xs"
      [mode]="mobileQuery.matches ? 'side' : 'over'"
      fixedTopGap="0"
      [opened]="mobileQuery.matches"
      [disableClose]="!mobileQuery.matches"
    >
      <app-sidebar
        *ngIf="isMobile == true"
        [isMobileActive]="isMobile"
        (messageEvent)="receiveMessage($event)"
        (click)="snav.close()"
      ></app-sidebar>

      <!-- <app-sidebar  (messageEvent)="receiveMessage($event)"></app-sidebar> -->

      <app-sidebar *ngIf="isMobile == false"></app-sidebar>
    </mat-sidenav>
    <!-- ============================================================== -->
    <!-- Sidebar - style you can find in sidebar.scss -->
    <!-- ============================================================== -->

    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-content class="page-wrapper">
      <div class="page-content">
        <!-- <app-spinner *ngIf="isLoading = true"></app-spinner> -->
        <div class="row m-0 p-0 w-100 justify-content-between">
          <div class="row col-xs-1 align-items-center">
            <a
              style="cursor: pointer; margin-left: 5px"
              (click)="snav.toggle()"
              (click)="WindowResize($event)"
              value="sidebarclosed"
            >
              <div class="fa fa-chevron-right rotate" style="color: black"></div
            ></a>
            <!-- <p class="ml-1 p-0">{{ navName }}</p> -->
          </div>
          <div style="cursor: pointer" (click)="logoutUser()">
            Logout
            <img class="ml-3" style="cursor: pointer" (click)="logoutUser()" src="../assets/images/logout.svg" />
          </div>
        </div>
        <!-- {{page.activatedRouteData.state}} -->
        <main >
          <router-outlet #page="outlet"></router-outlet>
        </main>

        <!-- <main>
                        <router-outlet #page="outlet"></router-outlet>
                      </main> -->
      </div>
    </mat-sidenav-content>
    <!-- ============================================================== -->
    <!-- Page container - style you can find in pages.scss -->
    <!-- ============================================================== -->
  </mat-sidenav-container>
</div>
