<div class="container-fluid p-0">
  <div class="heightfull">
    <app-spinner *ngIf="isLoading" [type]="'normal'"  ></app-spinner>
      <!-- <div class="back_color "></div> -->
      <div class="logoclass col-md-12 row">
          <img class="logoimg mt-3" src="../../../../assets/images/ic_logo.png">
      </div>
      <div class="contentbox col-md-10 pt-3" *ngIf="isLogin == true">
          <div class="content pt-5">
              <div class="row justify-content-center col-md-12 p-0 m-0">
                 
                  <!-- <div class="leftimg col-md-6 p-0 align-self-center">
                      <img class="leftimg" src="../../../../assets/images/cat-dog2.png">
                  </div> -->
                  <div class="col-md-5 p-0">
                      <div class="form_outer">
                          <div class="inner_form">
                              <div class="formcontent text-center">
                                  <p class="welcome">Welcome Back</p>
                                 <span>
                                  <p class="log-in">Log In</p>
                                  <span class="line"></span>
                                 </span>

                              </div>
                              <div class="inputs">
                                  <form [formGroup]="loginForm">
                                      <div class="form-group mt-3 mb-3">
                                          <label>Email</label>
                                          <input type="text" formControlName="email" class="form-control login_input" id="exampleInputEmail1"  placeholder="Email">
                                          <div *ngIf="loginForm.controls['email'].invalid && (loginForm.controls['email'].dirty || loginForm.controls['email'].touched)" class="errorinput text-left">
                                              <div *ngIf="loginForm.controls['email'].errors.required">
                                                *Email is required.
                                              </div>
                                              <div *ngIf="loginForm.controls['email'].errors.pattern">
                                                  *Invalid email .
                                                </div>
                                          </div>
                                        </div>

                                        <div class="form-group mb-3 position-relative">
                                          <label >Password</label>
                                          <input type="password" formControlName="password" [type]="show ? 'text' : 'password'" class="form-control login_input" id="exampleInputEmail1"  placeholder="Password">
                                          <img *ngIf="show == false" (click)="password()" class="show_password" src="../../../../assets/images/visibility.png" alt="">
                                           <img *ngIf="show == true"  (click)="password()" class="show_password" src="../../../../assets/images/view.png" alt="">
                                          <div *ngIf="loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)" class="errorinput text-left">
                                              
                                              <div *ngIf="loginForm.controls['password'].errors.required">
                                                *Password is required.
                                              </div>
                                              <div *ngIf="loginForm.controls['password'].errors.minlength">
                                                *Minimum password length is 8.
                                              </div>
                                          </div>
                                        </div>


                                        <div class="pt-3 pb-3">
                                          <button class="btn addCandidateBtn w-100" [disabled]="!loginForm.valid" (click)="createOtp()">LOG IN</button>
                                          <p class="version">v2.0.7
                                            
                                          </p>
                                           </div>
                                        <!-- <p class="forgot _add_cursor" (click)="navigateForgot('false')">Forgot Password?</p> -->

                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="contentbox col-md-10 pt-3" *ngIf="isLogin == false">
        <div class="content pt-5">
            <div class="row justify-content-center col-md-12 p-0 m-0">
               
                <!-- <div class="leftimg col-md-6 p-0 align-self-center">
                    <img class="leftimg" src="../../../../assets/images/cat-dog2.png">
                </div> -->
                <div class="col-md-5 p-0">
                  <div class="form_outer">
                    <div class="inner_form">
                        <div class="formcontent text-center">
                        
                           <span>
                            <p class="log-in">Verify OTP</p>
                            <span class="line"></span>
                           </span>

                        </div>
                        <div class="inputs mt-3 mx-auto text-center">
                          <ng-otp-input   (onInputChange)="onOtpChange($event)"  [config]="{length:4,allowNumbersOnly:true}"></ng-otp-input>

                          <p class="resend_text mt-2">Didn't recived the verification OTP? <span (click)="createOtp()" class="resend_Otp">Resend again</span></p>
                        </div>
                        <div class="pt-3 pb-3">
                          <button class="btn addCandidateBtn w-100" [disabled]="otpValue.length != 4" (click)="verifyOtp()">VERIFY</button>
                          <button class="btn addCandidateBtn w-100 mt-2" (click)="navigateOTP('true')">SIGN IN</button>
                          <p class="version">v2.0.9
                            
                          </p>
                           </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</div>