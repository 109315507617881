import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login.component";
import { RouterModule, Routes } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "../../shared/shared.module";
import { NgOtpInputModule } from "ng-otp-input";
import { LgFooterComponent } from "../../lg-footer/lg-footer.component";
import { AuthServiceLocal } from "../auth.service";
import { SigninComponent } from "./signin/signin.component";
import { ThankyouComponent } from "./thankyou/thankyou.component";

const router: Routes = [
  {
    path: "",
    component: LoginComponent,
    children: [
      { path: "", redirectTo: "signin", pathMatch: "full" },
      { path: "signin", component: SigninComponent, data: { state: "signin" } },
      { path: "thankyou", component: ThankyouComponent, data: { state: "forgot" } },
    ],
  },
];

@NgModule({
  exports: [],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(router),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgOtpInputModule,
  ],
  declarations: [LoginComponent, LgFooterComponent, SigninComponent, ThankyouComponent],
  providers: [AuthServiceLocal],
})
export class LoginModule {}
