import { MediaMatcher } from "@angular/cdk/layout";
import { NavigationStart, Router } from "@angular/router";
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  OnInit,
} from "@angular/core";
import { MenuItems } from "../../shared/menu-items/menu-items";
import { CommonService } from "../../shared/common.service";

import { StreamService } from "../../shared/stream.service";

declare var $: any;
/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: ["full.component.scss"],
  // animations: [
  //   trigger('routerTransition', [
  //     transition('* <=> *', [
  //       query(':enter, :leave', style({ position: 'fixed', opacity: 0 }),{ optional: true }),
  //       group([
  //         query(':enter', [
  //           style({ opacity:0 }),
  //           animate('600ms ease-in-out', style({ opacity:0 })),

  //         ],{ optional: true }),
  //         query(':leave', [
  //           style({ opacity:0.5 }),
  //           animate('600ms ease-in-out', style({ opacity:0 }))],{ optional: true }),
  //       ])
  //     ])
  //   ])
  //  ]
})
export class FullComponent implements OnDestroy, AfterViewInit, OnInit {
  mobileQuery: MediaQueryList;
  isMenuOpen = true;
  contentMargin: number;
  activeIndex: any;
  message: number;
  filterMessage: any;
  public screenWidth: any;

  public screenHeight: any;
  isMobile: boolean = false;
  detail: any;
  Name: string;
  routeName: string;
  profilePic: string;
  headerTitle = [];
  filterVal: string;
  userData: any;
  userRole: any;
  showScroll: boolean = true;
  isLoading: boolean = false;
  isPodcast: boolean = false;
  NoScroll = ["Search", "My Matches", "My Favorites"];
  currentScreenWidth: any;
  currentNavition: any;
  adminFilterAccess: [2, 3, 4, 5];
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public common: CommonService,
    public router: Router,
    private streamService: StreamService
  ) {
    router.events.subscribe((event) => {
      // if (event instanceof NavigationStart) {
      //   if (event.url.startsWith('/main/')) {
      //     this.message = parseInt(localStorage.getItem('activeIndex'));
      //     this.getHeader();
      //   }
      // }
    });
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit() {
    // this.activeIndex = localStorage.getItem("activeIndex")
    // this.userRole = localStorage.getItem("role")
    this.userData = this.common.getUser();
    // this.message = parseInt(localStorage.getItem('activeIndex'));
    // this.getHeaderInit();
    // this.streamService.getSubscription().subscribe(message => {
    //   this.message = message.data;
    //   this.getHeader();
    // });

    //     this.streamService.getFilterSubscription().subscribe(data => {
    // console.log(data)

    //       this.filterMessage = data;
    //       if(this.filterMessage.length <= 0) {
    //         this.filterVal = ""
    //       }
    //       console.log(this.filterMessage,"FILTERMESSGE")
    //     });

    $(".rotate").click(function () {
      $(this).toggleClass("down");
    });
    this.currentScreenWidth = screen.width;
    if (this.currentScreenWidth <= 780) {
      this.isMobile = true;
    }
  }
  navName: string;
  getHeader() {
    this.headerTitle = this.currentNavition;
    this.navName = this.headerTitle[this.message].name;
  }

  getHeaderInit() {
    this.headerTitle = this.currentNavition;
    this.message = parseInt(localStorage.getItem("activeIndex"));
    this.navName = this.headerTitle[this.message].name;
  }
  // checkFilterAccessValidation() {
  //   if(this.userData.role = 'admin') {
  //     for(let i =0; i < this.adminFilterAccess.length; i++) {

  //     }

  //   }

  // }
  changePasswordNav() {
    // this.checkFilterAccessValidation()

    if (this.userData.role == "manager") {
      localStorage.setItem("activeIndex", "5");
      this.getHeader();

      this.router.navigate(["/main/manager/change-password"]);
    }
    if (this.userData.role == "employee") {
      localStorage.setItem("activeIndex", "3");
      this.getHeader();
      this.router.navigate(["/main/employee/change-password"]);
    }
    if (this.userData.role == "admin") {
      localStorage.setItem("activeIndex", "10");
      this.getHeader();
      this.router.navigate(["/main/admin/change-password"]);
    }
  }

  logoutUser() {
    this.common.logOut();
  }
  filterFunction() {
    let filtervalue = this.filterVal.trim();
    this.streamService.streamFilterMessage(filtervalue);
  }
  enterFilterVal() {
    let filtervalue = this.filterVal.trim();
    this.streamService.streamFilterMessage(filtervalue);
  }

  // navigateSroll() {
  //   this.showScroll = this.NoScroll.indexOf(this.headerTitle) == -1;
  // }

  WindowResize(e) {
    // setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 260);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  readLocalStorageValue(key: string): number {
    return parseInt(localStorage.getItem(key));
  }
  logOut() {
    this.common.logOut();
  }
  ngAfterViewInit() {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth <= 780) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
