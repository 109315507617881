import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
} from "@angular/router";
import { CommonService } from "../shared/common.service";

@Injectable({ providedIn: "root" })
export class AuthGuardMain {
  constructor(private router: Router, private commonService: CommonService) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.commonService.isAuthenticated()) {
      let userData = this.commonService.getUser();
      console.log("token Data", userData);
      //  console.log("UserData role: ", userData.role, "Auth  Role", route.data.role,'roleIndexValue:::',route.data.role.indexOf(userData.role));
      // this link cheak weather you have permission of that module or not
      //permission define in main routing ,as Data role
      if (route.data.role && route.data.role.indexOf(userData.role) == -1) {
        this.router.navigate(["/signin"]);
        return false;
      } else {
        return true;
      }
    }
    this.router.navigate(["/signin"]);
    return false;
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.commonService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(["/signin"]);
    return false;
  }
}
