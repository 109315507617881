<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->
<div class="col-md text-center mt-3 mb-3">
  <!-- <span  (click)="snav.close()" class="cross">x</span> -->
</div>
<div class="logo text-center">
  <img
    (click)="naviate()"
    class="sidebar_logo"
    src="../../../../assets/images/ic_logo.png"
  />
</div>
<mat-nav-list appAccordion>
  <div class="mt-4">
    <mat-list-item
      class="mt-4"
      appAccordionLink
      *ngFor="let menuitem of menuItems.getMenuitem(); index as i"
      group="{{ menuitem.state }}"
    >

      <a
        (click)="activeRoute(i)"
        routerLinkActive="selected"
        appAccordionToggle
        [routerLink]="[menuitem.state]"
        *ngIf="menuitem.type === 'link'"
      >
        <span><img class="logo_icon" [src]="menuitem.iconUrl" /></span>
        <span
          ><b>{{ menuitem.name }}</b></span
        >
        <span fxFlex></span>
        <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
      </a>
    </mat-list-item>
  </div>
  <div></div>
</mat-nav-list>
