import { MenuItems } from "./menu-items/menu-items";
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective,
} from "./accordion";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
  MatFormFieldModule,
  MatDatepickerModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatRadioModule,
  MatTableModule,
  MatButtonModule,
  MatIconModule,
  MatToolbarModule,
  MatSidenavModule,
  MatListModule,
  MatMenuModule,
} from "@angular/material";
import { MatTabsModule } from "@angular/material/tabs";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatNativeDateModule } from "@angular/material/core";
import { SpinnerComponent } from "./spinner/spinner.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { SearchBtnComponent } from "./search-btn/search-btn.component";
import { ResetBtnComponent } from "./reset-btn/reset-btn.component";
import { AddBtnComponent } from "./add-btn/add-btn.component";
import { AlertComponent } from "./alert/alert.component";
import { AlertModule, AlertConfig } from "ngx-bootstrap/alert";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { ImageCropperModule } from "ngx-image-cropper";
import { CommonModule } from "@angular/common";
import { NoFile } from "./no-file/no-file";
import { NgSelectModule } from "@ng-select/ng-select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { UiSwitchModule } from "ngx-toggle-switch";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgOtpInputModule } from "ng-otp-input";
import { MatTooltipModule } from "@angular/material/tooltip";
import {
  NgbAlertModule,
  NgbPaginationModule,
} from "@ng-bootstrap/ng-bootstrap";

import { FlatpickrModule } from "angularx-flatpickr";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { ChartsModule } from "ng2-charts";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
// import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    SearchBtnComponent,
    ResetBtnComponent,
    AddBtnComponent,
    AlertComponent,
    NoFile,
  ],

  imports: [
    CommonModule,
    MatSnackBarModule,
    ChartsModule,
    FormsModule,
    AlertModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgbModalModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    NgSelectModule,
    MatOptionModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatRadioModule,
    MatMenuModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTableModule,
    MatButtonModule,
    MatListModule,
    NgxDatatableModule,
    SpinnerComponent,
    SearchBtnComponent,
    ResetBtnComponent,
    AlertComponent,
    AlertModule,
    NgMultiSelectDropDownModule,
    ImageCropperModule,
    CommonModule,
    NoFile,
    MatProgressSpinnerModule,
    UiSwitchModule,
    NgbAlertModule,
    NgbPaginationModule,
    DragDropModule,
    NgOtpInputModule,
    FlatpickrModule,
    CalendarModule,
    ChartsModule,
    MatSlideToggleModule,
  ],
  providers: [MenuItems, AlertConfig],
})
export class SharedModule {}
