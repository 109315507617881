import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { NetworkService } from "../shared/network.service";
@Injectable({
  providedIn: "root",
})
export class AuthServiceLocal {
  private pageChange = new BehaviorSubject("false");
  currentPage = this.pageChange.asObservable();

  constructor(private networkService: NetworkService) {}

  Page(message: string) {
    this.pageChange.next(message);
  }
  validateLogin(req: any) {
    return this.networkService.login("api/admin/login", req, null, null);
  }
  
  createOtp(req: any) {
    return this.networkService.login("api/otp/create", req, null, null);
  }
  verifyOtp(req: any) {
    return this.networkService.login("api/otp/verify", req, null, null);
  }
  uploadPdf(image: any) {
    const formData = new FormData();
    formData.append("image", image);
    return this.networkService.uploadImages("api/s3upload/image-upload", formData, null, "bearer");
  }
}
