import {
  EventEmitter,
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit,
  Output,
  Input,
  OnInit,
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { MenuItems } from "../../../shared/menu-items/menu-items";
import { CommonService } from "../../../shared/common.service";
import { StreamService } from "../../../../app/shared/stream.service";
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class AppSidebarComponent implements OnInit, OnDestroy {
  @Output() messageEvent = new EventEmitter<string>();
  @Input() isMobileActive: any;
  mobileQuery: MediaQueryList;
  isAllow: boolean = false;
  isPodcast: boolean = false;
  currentNavition = [];
  userData: any;
  userP: any;
  userG: any;
  userName: any;
  podcastVal: boolean = false;
  private _mobileQueryListener: () => void;
  decodedToken: any;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public common: CommonService,
    private streamService: StreamService,
    public router: Router
  ) {
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnInit() {
    this.userData = this.common.getUser();
    console.log("userData", this.userData);
  }

  naviate() {
    this.router.navigate(["/main/dashboard-management"]);
  }
  sendRouteVal(index) {
    this.streamService.streamMessage(index);
  }
  activeRoute(index) {
    localStorage.setItem("activeIndex", index);
    this.sendRouteVal(index);
  }

  readLocalStorageValue(key: string): number {
    return parseInt(localStorage.getItem(key));
  }
  logOut() {
    this.common.logOut();
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
