import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthComponent } from './auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthServiceLocal } from './auth.service';
import { routing } from './auth-routing.module';
import { LoginModule } from './login/login.module';
import { SharedModule } from '../shared/shared.module';

import { BrowserModule } from  '@angular/platform-browser';


@NgModule({
  imports: [CommonModule,LoginModule, routing, FormsModule, ReactiveFormsModule,SharedModule,BrowserModule
  ],
  declarations: [AuthComponent],
  providers: [AuthServiceLocal]
})
export class AuthModule { }
