import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainComponent } from "./main.component";

const routes: Routes = [
  {
    path: "main",
    component: MainComponent,
    children: [
      { path: "", redirectTo: "/main/dashboard-management", pathMatch: "full" },
      {
        path: "dashboard-management",

        loadChildren: () =>
          import("./dashboard-management/dashboard-management.module").then((m) => m.DashboardManagementModule),
      },
      {
        path: "reported-dating-management",

        loadChildren: () =>
          import("./reported-dating-management/reported-dating-management.module").then(
            (m) => m.ReportedDatingManagementModule
          ),
      },

      {
        path: "user-management",

        loadChildren: () => import("./user-management/user-management.module").then((m) => m.UserManagementModule),
      },
      {
        path: "venue-management",

        loadChildren: () => import("./venue-management/venue-management.module").then((m) => m.VenueManagementModule),
      },
      {
        path: "invoice-management",

        loadChildren: () =>
          import("./invoice-management/invoice-management.module").then((m) => m.InvoiceManagementModule),
      },

      {
        path: "payout-management",

        loadChildren: () =>
          import("./payout-management/payout-management.module").then((m) => m.PayoutManagementModule),
      },

      {
        path: "tax-management",

        loadChildren: () =>
          import("./tax-management/tax-management.module").then((m) => m.TaxManagementModule),
      },
      {
        path: "service-fee",

        loadChildren: () =>
          import("./service-fee/service-fee.module").then((m) => m.ServiceFeeModule),
      },
      {
        path: "refund-management",

        loadChildren: () =>
          import("./refund-management/refund-management.module").then((m) => m.RefundManagementModule),
      },
      {
        path: "country-management",

        loadChildren: () =>
          import("./country-management/country-management.module").then((m) => m.CountryManagementModule),
      },
      {
        path: "transaction-history-management",

        loadChildren: () =>
          import("./transaction-history-management/transaction-history-management.module").then(
            (m) => m.TransactionHistoryManagementModule
          ),
      },
      {
        path: "city",

        loadChildren: () =>
          import("../main/category-management/category-management.module").then((m) => m.CategoryManagementModule),
      },
      {
        path: "event-management",

        loadChildren: () => import("./event-management/event-management.module").then((m) => m.EventManagementModule),
      },
      {
        path: "user-event",

        loadChildren: () => import("./user-event/user-event.module").then((m) => m.UserEventModule),
      },
      {
        path: "booking-management",

        loadChildren: () =>
          import("./booking-management/booking-management.module").then((m) => m.BookingManagementModule),
      },
      {
        path: "post-management",

        loadChildren: () => import("./post-management/post-management.module").then((m) => m.PostManagementModule),
      },
      {
        path: "rating-management",

        loadChildren: () =>
          import("./rating-management/rating-management.module").then((m) => m.RatingManagementModule),
      },
      {
        path: "promo-code",

        loadChildren: () =>
          import("./coupon-management/coupon-management.module").then((m) => m.CouponManagementModule),
      },
      {
        path: "banner-management",

        loadChildren: () =>
          import("./banner-management/banner-management.module").then((m) => m.BannerManagementModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {}
