import { Injectable } from "@angular/core";
import { CommonService } from "../common.service";
export interface Menu {
  state: string;
  name: string;
  type: string;
  iconUrl: string;
  iconUrlActive: string;
}
const MENUITEMS = [
  {
    state: "dashboard-management",
    name: "Dashboard",
    iconUrl: "assets/images/ic_dashboard_white.svg",
    type: "link",
    iconUrlActive: "assets/images/ic_dashboard_white.svg",
  },
  {
    state: "user-management",
    name: "Users",
    iconUrl: "assets/images/ic_user_white.svg",
    type: "link",
    iconUrlActive: "assets/images/dashboard_color.png",
  },
  {
    state: "venue-management",
    name: "Manage Venues",
    iconUrl: "assets/images/Venue.png",
    // iconUrl: "assets/images/ic_user_white.svg",
    type: "link",
    iconUrlActive: "assets/images/Venue.png",
  },
  // {
  //   state: "city",
  //   type: "link",
  //   name: "Manage City",
  //   iconUrl: "assets/images/ic_user_white.svg",
  //   iconUrlActive: "assets/images/city copy.svg",
  //   access: "city",
  // },
  {
    state: "event-management",
    name: "Manage Event",
    iconUrl: "assets/images/Event.png",
    type: "link",
    iconUrlActive: "assets/images/Event.png",
  },
  {
    state: "user-event",
    name: "Manage User Event",
    iconUrl: "assets/images/Employees_icon_color.svg",
    type: "link",
    iconUrlActive: "assets/images/Employees_icon_color.svg",
  },
  {
    state: "booking-management",
    name: "Manage Bookings",
    iconUrl: "assets/images/Bookings.png",
    type: "link",
    iconUrlActive: "assets/images/Bookings.png",
  },
  {
    state: "promo-code",
    name: "Manage Coupon",
    iconUrl: "assets/image/coupon-512.png",
    type: "link",
    iconUrlActive: "assets/image/coupon-256.png",
  },
  {
    state: "banner-management",
    name: "Manage Banner",
    iconUrl: "assets/image/coupon-512.png",
    type: "link",
    iconUrlActive: "assets/image/coupon-256.png",
  },
  {
    state: "tax-management",
    name: "Tax",
    iconUrl: "assets/images/tax.jpeg",
    type: "link",
    iconUrlActive: "assets/images/tax.jpeg",
  },
  {
    state: "service-fee",
    name: "Service Fee",
    iconUrl: "assets/image/Fee.png",
    type: "link",
    iconUrlActive: "assets/image/Fee.png",
  },
  {
    state: "post-management",
    name: "Reported Post",
    iconUrl: "assets/images/Post.png",
    type: "link",
    iconUrlActive: "assets/images/Post.png",
  },
  {
    state: "rating-management",
    name: "Rating",
    iconUrl: "assets/images/Rating.png",
    type: "link",
    iconUrlActive: "assets/images/Rating.png",
  },
  {
    state: "reported-dating-management",
    name: "Reported-Dating-Post",
    iconUrl: "assets/images/calander_view.svg",
    type: "link",
    iconUrlActive: "assets/images/calander_view.svg",
  },
  {
    state: "payout-management",
    name: "Payout",
    iconUrl: "assets/images/payout.png",
    type: "link",
    iconUrlActive: "assets/images/payout.png",
  },
  // {
  //   state: "refund-management",
  //   name: "Refund",
  //   iconUrl: "assets/images/refund.png",
  //   type: "link",
  //   iconUrlActive: "assets/images/refund.png",
  // },
  {
    state: "invoice-management",
    name: "Invoice",
    iconUrl: "assets/images/invoice-1.png",
    type: "link",
    iconUrlActive: "assets/images/invoice-1.png",
  },
  // {
  //   state: "transaction-history-management",
  //   name: "Transaction-History",
  //   iconUrl: "assets/images/transaction-history.png",
  //   type: "link",
  //   iconUrlActive: "assets/images/transaction-history.png",
  // },
  {
    state: "country-management",
    name: "Country",
    iconUrl: "assets/images/country.png",
    type: "link",
    iconUrlActive: "assets/images/country.png",
  },
  // { state: 'search', name: 'Search', iconUrl:'assets/images/search_color.png', type: 'link',iconUrlActive:'assets/images/search_white.png'},
  // { state: 'message', name: 'Message', iconUrl:'assets/images/message_white.png', type: 'link',iconUrlActive:'assets/images/message_color.png'},
  // { state: 'match', name: 'My Matches', iconUrl:'assets/images/match_white.png', type: 'link',iconUrlActive:'assets/images/match_color.png'},
  // { state: 'profile', name: 'My Profile', iconUrl:'assets/images/profile_white.png', type: 'link',iconUrlActive:'assets/images/profile_color.png'},
  // { state: 'subscription', name: 'Subscription', iconUrl:'assets/images/ic_subscription.svg', type: 'link',iconUrlActive:'assets/images/ic_Subscription_fill.svg'},
  // { state: 'payment', name: 'Payment', iconUrl:'assets/images/payment_white.png', type: 'link',iconUrlActive:'assets/images/payment_color.png'},
  // { state: 'favourite', name: 'My Favorite', iconUrl:'assets/images/fav_white.png', type: 'link',iconUrlActive:'assets/images/fav_color.png'},
  // { state: 'blog', name: 'My Blogs', iconUrl:'assets/images/writing_white.png', type: 'link',iconUrlActive:'assets/images/writing_color.png'},
];

@Injectable()
export class MenuItems {
  constructor(private commonService: CommonService) {}
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
