import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']

})
export class LoginComponent implements OnInit {
  constructor(
    ) {
      
    }

  ngOnInit() {
   
  }

  isScroll : boolean = false;
  ishow : boolean = false;
  onScroll(event: any) {
		// visible height + pixel scrolled >= total height 
	
    if (event.target.scrollTop > 100) {
      this.isScroll = true;
      this.ishow = true;
    }
    if (event.target.scrollTop < 100) {
      this.isScroll = false;
      this.ishow = false;
    }
		if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
		 

		}
		if( event.target.scrollTop == 0) {
      this.isScroll = false;
      this.ishow = false;
    }
		const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    }
    gotoTop() {
      const mainDiv = document.getElementById('mainDIV');
       mainDiv.scrollTop = 0;		
      }
  readLocalStorageValue(key: string): string {
    return localStorage.getItem(key);
  }




} 
